import { Chip, Paper } from "@mui/material";
import { Space } from "antd";
import React, { useEffect } from "react";

import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

import "./User.css";

import DataTableUi from "../../components/data-table/DataTableUi";
import TitleBoxUi from "../../components/title-box/TitleBoxUi";
import ActionBtn from "../../components/action-btn/ActionBtn";
import { GET_ALL_USERS, IMPERSONATE_USER } from "./graphQL";
import useGraphQLMutator from "../../hooks/useGraphQLMutator";
import { isLoading, isLoggedInVar, userPermissions } from "../../store/cache";
import { promptBox } from "../../utils/helpers";
import { ACCESS_TOKEN, MY_PERMISSIONS } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";

const UsersImpersonate = () => {
  const navigate = useNavigate();

  const { mutation: impersonate } = useGraphQLMutator(
    IMPERSONATE_USER,
    "impersonate",
    [GET_ALL_USERS],
    (data) => {
      onMutated(data);
      isLoading(false);
    },
    `Successfully user impersonated!`
  );

  const onMutated = (data) => {
    localStorage.setItem(ACCESS_TOKEN, JSON.stringify(data.accessToken));

    isLoggedInVar(true);

    localStorage.setItem(MY_PERMISSIONS, JSON.stringify(data.permissions));

    userPermissions(data.permissions);

    navigate("/dashboard", { replace: true });
  };

  const impersonateUser = (uuid) => {
    promptBox(() => {
      isLoading(true);
      impersonate({
        variables: {
          userUuid: uuid,
        },
      });
    }, ``);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Site",
      key: "site",
      render: (_, rec) => {
        const site = rec.site.name;
        return <Chip size="small" label={site} />;
      },
    },
    {
      title: "Department",
      key: "deptment",
      render: (_, rec) => {
        const dept = rec.department.name;
        return <Chip size="small" label={dept} />;
      },
    },
    {
      title: "Joined",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <ActionBtn
            title="Impersonate User"
            onClickIcon={() => impersonateUser(record.uuid)}
            icon={<RocketLaunchIcon color="info" fontSize="inherit" />}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <TitleBoxUi
        title=" Impersonate Users"
        icon={<RocketLaunchIcon />}
      ></TitleBoxUi>

      <Paper
        sx={{
          mt: 2,
          padding: 2,
        }}
      >
        <DataTableUi
          columns={columns}
          query={GET_ALL_USERS}
          queryName="getAllUsers"
        />
      </Paper>
    </>
  );
};

export default UsersImpersonate;
