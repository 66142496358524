export const ACCESS_TOKEN = "accessToken";
export const INVALID_LOGIN = "INVALID_LOGIN";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const MY_PERMISSIONS = "MY_PERMISSIONS";
export const SYSTEM_INFO = "SYSTEM_INFO";

export const BASE_URL = "https://goldenruleschallenge.com/api"; // Prod
// export const BASE_URL = "http://199.192.22.164:3001"; // Testing
// export const BASE_URL = "http://204.10.160.56:3001";
//export const BASE_URL = "http://localhost:3001"; // Backup
//export const BASE_URL = "https://golden-rules-backend.herokuapp.com";
//export const BASE_URL = "http://199.192.20.108:3002"; // Testing

export const LOGIN_URL = `${BASE_URL}/auth/login`;
export const GRAPHQL_URL = `${BASE_URL}/graphql`;

export const APP_NAME = "Golden Rules";

export const PRIMARY_COLOR = "rgb(239, 69, 69)";

export const EXCEL_EXT =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
